const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
      case '':
    break;

    default: return state;
  }
};

