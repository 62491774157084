import App from './App';
import { createBrowserHistory } from 'history';
import { createStore } from 'redux'
import Provider from 'react-redux/es/components/Provider';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import reducer from './reducers/index'
import registerServiceWorker from './registerServiceWorker';
import { Router } from 'react-router-dom';
import ScrollToTop from './app/Utils/ScrollToTop/ScrollToTop';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import * as FullStory from '@fullstory/browser';
import { useMediaQuery } from "react-responsive";

// import 'bootstrap/dist/css/bootstrap.min.css';

const trackPageView = (location) => {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
};

const checkForGALoaded = () =>
  new Promise((resolve) => {
    const waitForElement = () => {
      if ('ga' in window) {
        resolve();
      } else {
        window.requestAnimationFrame(waitForElement);
      }
    };
    waitForElement();
  }
);

const history = createBrowserHistory();
if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-143250826-1');
  // Track direct visit to a page (e.g. from campaign)
  checkForGALoaded().then(() => trackPageView(history.location));
  // And each consequent redirect
  history.listen(trackPageView);

  FullStory.init({ orgId: 'RAQFM' });
}

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const ThemeProvider = ({ children }) => {
  const isSm = useMediaQuery({ minWidth: 576 });

  return (
    <GridThemeProvider
      gridTheme={{
        breakpoints: {
          xl: 1600,
          lg: 1200,
          // xl: 1200,
          // lg: 992,
          md: 768,
          sm: 576,
          xs: 575,
        },
        container: {
          padding: isSm ? 15 : 30,
          maxWidth: {
            xl: 1540,
            lg: 1140,
            // xl: 1140,
            // lg: 960,
            md: 720,
            sm: 540,
            xs: 540,
          },
        },
      }}
    >
      {children}
    </GridThemeProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById('root'));

registerServiceWorker();
