import { BaseCSS } from 'styled-bootstrap-grid';
import { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';
import React, { Component, Fragment } from 'react';
import { Route, Switch } from "react-router-dom";

// import './App.css'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #101318;
    background-color: #f4f6f8;
  }

  a {
    text-decoration: none;
    color: #635bff;
  }

  button {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    font-weight: 400;
  }

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'RM Neue', sans-serif;
	}
`;

const Hubspot = Loadable({
	loader: () => import('./app/TrackingPixels/components/Hubspot'),
	loading() {
		return null
	}
});

const IntercomTracking = Loadable({
    loader: () => import('./app/TrackingPixels/components/Intercom'),
    loading() {
        return null
    }
});

// const Hotjar = Loadable({
// 	loader: () => import('./app/TrackingPixels/components/Hotjar'),
// 	loading() {
// 		return null
// 	}
// });

const MigrateMagento1 = Loadable({
	loader: () => import('./app/CMS/components/MigrateMagento1'),
	loading() {
		return <div></div>
	}
});

const BusinessIntelligenceAnalytics = Loadable({
	loader: () => import('./app/CMS/components/BusinessIntelligenceAnalytics'),
	loading() {
		return <div></div>
	}
});

// const AdobeSummitSpeedway = Loadable({
// 	loader: () => import('./app/CMS/components/AdobeSummitSpeedway'),
// 	loading() {
// 		return <div></div>
// 	}
// });

const Contact = Loadable({
	loader: () => import('./app/Contact/components/Contact'),
	loading() {
		return <div></div>
	}
});
const Partners = Loadable({
	loader: () => import('./app/Partners/components/Partners'),
	loading() {
		return <div></div>
	}
});
const Platforms = Loadable({
	loader: () => import('./app/Platforms/components/Platforms'),
	loading() {
		return <div></div>
	}
});
const Header = Loadable({
	loader: () => import('./app/Header/components/Header'),
	loading() {
		return <div></div>
	}
});
const Footer = Loadable({
	loader: () => import('./app/Footer/components/Footer'),
	loading() {
		return <div></div>
	}
});
const Home = Loadable({
	loader: () => import('./app/Home/components/Home'),
	loading() {
		return <div></div>
	}
});
const About = Loadable({
	loader: () => import('./app/About/components/About'),
	loading() {
		return <div></div>
	}
});
const Portfolio = Loadable({
	loader: () => import('./app/Portfolio/components/Portfolio'),
	loading() {
		return <div></div>
	}
});
const Insights = Loadable({
	loader: () => import('./app/Insights/components/Insights'),
	loading() {
		return <div></div>
	}
});
const Podcast = Loadable({
	loader: () => import('./app/Podcast/components/Podcast'),
	loading() {
		return <div></div>
	}
});
const Careers = Loadable({
	loader: () => import('./app/Careers/components/Careers'),
	loading() {
		return <div></div>
	}
});
const Capabilities = Loadable({
	loader: () => import('./app/Capabilities/components/Capabilities'),
	loading() {
		return <div></div>
	}
});
const TermsOfUse = Loadable({
	loader: () => import('./app/CMS/components/TermsOfUse'),
	loading() {
		return <div></div>
	}
});
const Cookies = Loadable({
	loader: () => import('./app/CMS/components/Cookies'),
	loading() {
		return <div></div>
	}
});
const Disclaimer = Loadable({
	loader: () => import('./app/CMS/components/Disclaimer'),
	loading() {
		return <div></div>
	}
});
const Privacy = Loadable({
	loader: () => import('./app/CMS/components/Privacy'),
	loading() {
		return <div></div>
	}
});
const Press = Loadable({
	loader: () => import('./app/CMS/components/Press'),
	loading() {
		return <div></div>
	}
});
const OpenSource = Loadable({
	loader: () => import('./app/CMS/components/OpenSource'),
	loading() {
		return <div></div>
	}
});
const Events = Loadable({
	loader: () => import('./app/Events/components/Events'),
	loading() {
		return <div></div>
	}
});
const PageNotFound = Loadable({
	loader: () => import('./app/PageNotFound/components/PageNotFound'),
	loading() {
		return <div></div>
	}
});
const Pwa = Loadable({
	loader: () => import('./app/CMS/components/Pwa'),
	loading() {
		return <div></div>
	}
});

const SchedulePwaDemo = Loadable({
	loader: () => import('./app/CMS/components/LandingPage/SchedulePwaDemo'),
	loading() {
		return <div></div>
	}
});
const WeDriveCreative = Loadable({
	loader: () => import('./app/CMS/components/LandingPage/WeDriveCreative'),
	loading() {
		return <div></div>
	}
});
const WeDriveMagento = Loadable({
	loader: () => import('./app/CMS/components/LandingPage/WeDriveMagento'),
	loading() {
		return <div></div>
	}
});
const WeDriveDigitalMarketing = Loadable({
	loader: () => import('./app/CMS/components/LandingPage/WeDriveDigitalMarketing'),
	loading() {
		return <div></div>
	}
});

// const Popup = Loadable({
// 	loader: () => import('./app/GDPR/components/Popup'),
// 	loading() {
// 		return <div></div>
// 	}
// });

// import Contact from './app/Contact/components/Contact';
// import Partners from './app/Partners/components/Partners';
// import Header from './app/Header/components/Header';
// import Footer from './app/Footer/components/Footer';
// import Home from "./app/Home/components/Home";
// import About from "./app/About/components/About";
// import Portfolio from "./app/Portfolio/components/Portfolio";
// import Insights from "./app/Insights/components/Insights";
// import Careers from "./app/Careers/components/Careers";
// import Capabilities from './app/Capabilities/components/Capabilities';
//// import CMS from './app/CMS/components/CMS';
// import TermsOfUse from './app/CMS/components/TermsOfUse';
// import Cookies from './app/CMS/components/Cookies';
// import Disclaimer from './app/CMS/components/Disclaimer';
// import Privacy from './app/CMS/components/Privacy';
// import Press from './app/CMS/components/Press';
// import OpenSource from './app/CMS/components/OpenSource';
// import Events from './app/Events/components/Events';
// import Popup from './app/GDPR/components/Popup';

class App extends Component {

  render() {
    return (
      <Fragment>
        <BaseCSS />
        <GlobalStyle />
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="Specializing in creative, development, devops, digital marketing, quality assurance, and strategy ecommerce services. We create brand experiences." />
          <title>Design, Development + Digital Marketing Ecommerce Agency | RMG</title>
          <link rel="canonical" href="https://www.rmgmedia.com/" />
        </Helmet>

        <Header />
        <Switch>
          <Route exact path="/" component={ Home } />
          <Route path="/about" component={ About } />
          <Route path="/capabilities" component={ Capabilities } />
          <Route path="/portfolio" component={ Portfolio } />
          <Route path="/insights" component={ Insights } />
					<Route path="/digital-conversations" component={ Podcast } />
          <Route path="/careers" component={ Careers } />
          <Route path="/contact" component={ Contact } />
          <Route path="/events" component={ Events } />
          <Route path="/partners" component={ Partners } />
					<Route path="/platforms" component={ Platforms } />
          <Route path="/open-source" component={ OpenSource } />
          <Route path="/press" component={ Press } />
          <Route path="/legal/terms" component={ TermsOfUse } />
          <Route path="/legal/cookies" component={ Cookies } />
          <Route path="/legal/disclaimer" component={ Disclaimer } />
          <Route path="/privacy" component={ Privacy } />
					<Route path="/pwa" component={ Pwa } />
					<Route path="/schedule-pwa-demo" component={ SchedulePwaDemo } />
          <Route path="/magento-1-migration" component={ MigrateMagento1 } />
          <Route path="/business-intelligence-analytics" component={ BusinessIntelligenceAnalytics } />
          <Route path="/we-drive-creative-experiences" component={ WeDriveCreative } />
					<Route path="/we-drive-magento-solutions" component={ WeDriveMagento } />
					<Route path="/we-drive-digital-marketing" component={ WeDriveDigitalMarketing } />
					<Route component={ PageNotFound } />

          {/*<Route path="/adobe-summit-speedway" component={ AdobeSummitSpeedway } />*/}
        </Switch>
        <Footer />
        {/* <Popup /> */}

          <Hubspot hubspotId={2611398}/>
          <IntercomTracking appID={"ll3nieh0"}/>
      </Fragment>
    );
  }
}

export default App;
